















import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class PageFooter extends Vue {
    async goToDesktop() {
      window.location.href = this.$store.state.config.hyrmaBaseUrl + '/Hyrma/Administration';
    }
  }
